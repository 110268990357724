import { ResponsiveLine } from '@nivo/line';

const commonProperties = {
  animate: true,
};

const legends = [
  {
    anchor: 'bottom-right',
    direction: 'column',
    justify: false,
    translateX: 100,
    translateY: 0,
    itemsSpacing: 0,
    itemDirection: 'left-to-right',
    itemWidth: 80,
    itemHeight: 20,
    itemOpacity: 0.75,
    symbolSize: 12,
    symbolShape: 'circle',
    symbolBorderColor: 'rgba(0, 0, 0, .5)',
    effects: [
      {
        on: 'hover',
        style: {
          itemBackground: 'rgba(0, 0, 0, .03)',
          itemOpacity: 1,
        },
      },
    ],
  },
];

export default function LineGraph(props) {
  const {
    data = [],
    axisBottomFormat,
    colors,
    showLegends = false,
    isMobileScreen = false,
    graphData = {},
    axisBottomRotation = {},
  } = props;

  return (
    <ResponsiveLine
      {...commonProperties}
      margin={{
        top: 50,
        right: isMobileScreen ? 20 : 50,
        bottom: isMobileScreen ? 80 : 50,
        left: isMobileScreen ? 20 : 60,
      }}
      data={data}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      // yFormat=" >-.2f"
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isMobileScreen ? 90 : 0,
        legendOffset: isMobileScreen ? -80 : 36,
        legendPosition: 'middle',
        ...(axisBottomFormat && {
          format: axisBottomFormat,
        }),
        ...axisBottomRotation,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      enableGridX={false}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableArea
      areaOpacity={0.1}
      useMesh
      enableSlices="x"
      {...(showLegends && { legends })}
      {...(colors && { colors })}
      {...graphData}
    />
  );
}
